import useAuthStore from '@/store/auth'

export const checkDoctorShiftAuth = async () => {
  // check ip is Honchen or not
  // if ip is not honchen, need to check the auth
  // else dont check the auth
  const auth = useAuthStore()
  const { postCheckUserIpIsHonchen } = auth
  const isIpCorrect = await postCheckUserIpIsHonchen()
  if(isIpCorrect) return true

  const isAuthenticated = await useAuthenticated()
  if(isAuthenticated) return true
  return false
}

export const useCheckUserIpIsHonchen = async (role) => {
  const auth = useAuthStore()
  const { postCheckUserIpIsHonchen } = auth

  if(role === 'Administrator' || role === "HeadNurse") return true

  const isIpCorrect = await postCheckUserIpIsHonchen()
  if(!isIpCorrect) return false
  return true
}

export const useCheckPermission = async () => {
  const { postPermission } = useAuthStore()

  try{
    const user = await postPermission()
    return user
  }
  catch(err){
    return null
  }
}

export const useAuthenticated = async () => {
  const { postCheckLogin } = useAuthStore()

  try{
    const res = await postCheckLogin()
    if(res.ok) return true
    else return false
  }
  catch(err){
    return false
  }
}

export const useCheckUserIsAlreadyLogin = () => {
  const { jwtToken, user } = useAuthStore()
  if(jwtToken && user)  return true
  return false
}