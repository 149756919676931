import { createRouter, createWebHashHistory } from 'vue-router'
import { roleControlMiddleware } from '@/middleware/roleControlMiddleware'
import { middleware } from '@/middleware/middleware'

const allRoles = [
  'Administrator',
  'Doctor',
  'HeadNurse',
  'Beautician',
  'Beautician_AA',
  'Receptionist',
  'HeadReceptionis'
]

const routes = [
  {
    path: "/:pathMatch(.*)*",
    redirect: '/appointment',
  },
  {
    path: '/',
    name: 'LayOut',
    redirect: 'medicalRecord',
    component: () => import('../views/LayOut.vue'),
    children: [
      
      {
        path: '/',
        beforeEnter: () => roleControlMiddleware(allRoles),
        children: [
          { // 約診,
            path: '/appointment',
            children: [
              {
                name: 'appointmentIndex',
                path: '',
                component: () => import('../views/appointment/appointmentIndex.vue'),
                meta: {title: '約診'}
              },
              {
                name: 'appointmentDetail',
                path: ':customerId',
                component: () => import('../views/appointment/appointmentIndex.vue'),
                meta: {title: '約診'}
              }
            ]
          },
          { // 約診進階搜尋
            name: 'appointmentAdvanceSearch',
            path: '/appointmentAdvanceSearch',
            component: () => import('../views/appointmentAdvanceSearch/AppointmentAdvanceSearch.vue'),
            meta: {title: '約診進階搜尋'}
          },
          { // 到診進階搜尋
            name: 'checkInAdvanceSearch',
            path: '/checkInAdvanceSearch',
            component: () => import('../views/checkInAdvanceSearch/CheckInAdvanceSearch.vue'),
            meta: {title: '到診進階搜尋'}
          },
          { // 同意書進階搜尋
            name: 'advancedSearchConsentRecordList',
            path: '/advancedSearchConsentRecordList',
            component: () => import('../views/advancedSearchConsentRecordList/AdvancedSearchConsentRecordList.vue'),
            meta: {title: '同意書進階搜尋'}
          },
          { // 客戶設定
            name: 'customerSetting',
            path: '/customerSetting',
            component: () => import('../views/stock/customerSetting/CustomerSetting.vue'),
            meta: {title: '客戶設定'}
          },
          { // 儲值量管理
            name: 'storedValueManagement',
            path: '/storedValueManagement',
            component: () => import('../views/stock/storedValueManagement/StoredValueManagement.vue'),
            meta: {title: '儲值量管理'}
          },
        ]
      },
      { // 醫美師績效
        name: 'beauticianPerformance',
        path: '/beauticianPerformance',
        beforeEnter: () => roleControlMiddleware(['Administrator','HeadNurse','Beautician_AA']),
        component: () => import('../views/stock/beauticianPerformance/BeauticianPerformance.vue'),
        meta: {title: '醫美師績效'}
      },
      {
        path: '/',
        beforeEnter: () => roleControlMiddleware(['Administrator','HeadNurse','Beautician','Beautician_AA', 'HeadReceptionis', 'Doctor']),
        children: [
          { // 跟診
            name: 'track',
            path: '/track',
            component: () => import('../views/track/trackIndex.vue'),
            meta: {title: '跟診'}
          },
          { // 控台
            name: 'controlTrack',
            path: '/controlTrack',
            component: () => import('../views/track/trackIndex.vue'),
            meta: {title: '控台'}
          },
          { // 關心紀錄
            name: 'careRecord',
            path: '/careRecord',
            component: () => import('../views/careRecord/careRecordIndex.vue'),
            meta: {title: '關心紀錄'}
          },
          { // 耗材出單紀錄表(控台)
            name: 'orderRecordTable',
            path: '/orderRecordTable',
            component: () => import('../views/counter/orderRecordTable/orderRecordTable.vue'),
            meta: {title: '耗材出單紀錄表(控台)'}
          },
          { // 耗材樓層庫存(控台)
            name: 'floorInventory',
            path: '/floorInventory',
            component: () => import('../views/counter/floorInventory/floorInventory.vue'),
            meta: {title: '耗材樓層庫存(控台)'}
          },
          
          { // 醫美師達成率
            name: 'beauticianAchievementRate',
            path: '/beauticianAchievementRate',
            component: () => import('../views/stock/beauticianAchievementRate/BeauticianAchievementRate.vue'),
            meta: {title: '醫美師達成率'}
          },
          { // 醫美師達成率細項
            name: 'beauticianAchievementRateDetail',
            path: '/beauticianAchievementRateDetail',
            component: () => import('../views/beauticianAchievementRateDetail/BeauticianAchievementRateDetail.vue'),
            meta: {title: '醫美師達成率細項'}
          },
          { // 當日微整類療程使用統計
            name: 'manageShiftWork',
            path: '/manageShiftWork',
            component: () => import('../views/stock/manageShiftWork/ManageShiftWork.vue'),
            meta: {title: '當日微整類療程使用統計'}
          },
          { //  醫美師工作點數
            name: 'beauticianSubcoursePointAndList',
            path: '/beauticianSubcoursePointAndList',
            component: () => import('../views/beauticianSubcoursePointAndList/BeauticianSubcoursePointAndList.vue'),
            meta: {title: ' 醫美師工作點數'}
          },
        ]
      },
      {
        name: 'salesInvoiceCreation',
        path: '/salesInvoiceCreation',
        component: () => import('../views/counter/salesInvoiceCreation/salesInvoiceCreation.vue'),
        meta: {title: '銷售單據建立'},
        children: [
          {
            path: '',
            name: 'salesInvoiceCreation/default',
            redirect: {name: 'buyGoods'}
          },
          { 
            path: 'buyGoods',
            name:'salesInvoiceCreation/buyGoods',
            beforeEnter: () => roleControlMiddleware(['Administrator','HeadNurse','Receptionist','HeadReceptionis']),
            component: () => import('../views/counter/salesInvoiceCreation/pages/BuyGoods.vue')
          },
          { 
            path: 'customerOrder',
            name:'salesInvoiceCreation/customerOrder',
            beforeEnter: () => roleControlMiddleware(['Administrator','HeadNurse','Receptionist','HeadReceptionis']),
            component: () => import('../views/counter/salesInvoiceCreation/pages/CustomerOrder.vue')
          },
          { 
            path: 'storedValueManagement',
            name:'salesInvoiceCreation/storedValueManagement',
            beforeEnter: () => roleControlMiddleware(['Administrator','HeadNurse','Receptionist','HeadReceptionis','Beautician_AA', 'Beautician']),
            component: () => import('../views/counter/salesInvoiceCreation/pages/storedValueManagement/StoredValueManagement.vue')
          },
          { 
            path: 'outpatientOpd',
            name:'salesInvoiceCreation/outpatientOpd',
            beforeEnter: () => roleControlMiddleware(['Administrator','HeadNurse','Receptionist','HeadReceptionis']),
            component: () => import('../views/counter/salesInvoiceCreation/pages/OutpatientOpd.vue')
          },
          { 
            path: 'singleCharge',
            name:'salesInvoiceCreation/singleCharge',
            beforeEnter: () => roleControlMiddleware(['Administrator','HeadNurse','Receptionist','HeadReceptionis']),
            component: () => import('../views/counter/salesInvoiceCreation/pages/SingleCharge.vue')
          },
          { 
            path: 'navigateToSweepstakesView',
            name:'salesInvoiceCreation/navigateToSweepstakesView',
            beforeEnter: () => roleControlMiddleware(['Administrator','HeadNurse','Receptionist','HeadReceptionis']),
            component: () => import('../views/counter/salesInvoiceCreation/pages/NavigateToSweepstakesView.vue')
          },
          { 
            path: 'voucherManagement',
            name:'salesInvoiceCreation/voucherManagement',
            beforeEnter: () => roleControlMiddleware(['Administrator','HeadNurse','Receptionist','HeadReceptionis']),
            component: () => import('../views/counter/salesInvoiceCreation/pages/VoucherManagement.vue')
          },
          { 
            path: 'voucherInAndOutManagement',
            name:'salesInvoiceCreation/voucherInAndOutManagement',
            beforeEnter: () => roleControlMiddleware(['Administrator','HeadNurse','Receptionist','HeadReceptionis']),
            component: () => import('../views/counter/salesInvoiceCreation/pages/VoucherInAndOutManagement.vue')
          },
          { 
            path: 'voucherReturnManagement',
            name:'salesInvoiceCreation/voucherReturnManagement',
            beforeEnter: () => roleControlMiddleware(['Administrator','HeadNurse','Receptionist','HeadReceptionis']),
            component: () => import('../views/counter/salesInvoiceCreation/pages/VoucherReturnManagement.vue')
          },
          { 
            path: 'depositReturnVoucher',
            name:'salesInvoiceCreation/depositReturnVoucher',
            beforeEnter: () => roleControlMiddleware(['Administrator','HeadNurse','Receptionist','HeadReceptionis']),
            component: () => import('../views/counter/salesInvoiceCreation/pages/DepositReturnVoucher.vue')
          },
          { 
            path: 'reverseEntry',
            name:'salesInvoiceCreation/reverseEntry',
            beforeEnter: () => roleControlMiddleware(['Administrator','HeadNurse','Receptionist','HeadReceptionis']),
            component: () => import('../views/counter/salesInvoiceCreation/pages/reverseEntry/ReverseEntry.vue')
          },

        ]
      },
      {
        path: '/',
        beforeEnter: () => roleControlMiddleware(['Administrator','HeadNurse','Receptionist','HeadReceptionis']),
        children: [
          { // 到診
            name: 'checkIn',
            path: '/checkIn',
            component: () => import('../views/checkIn/checkInIndex.vue'),
            meta: {title: '到診'}
          },
          {
            // 客戶儲值量電子簽名(ipad)
            name: 'storeValueSignature(ipad)',
            path: '/storeValueSignature',
            component: () => import('../views/counter/storeValueSignature/storeValueSignature.vue'),
            meta: {title: '客戶儲值量電子簽名(ipad)'}
          },
          { // 退貨
            name: 'refundView',
            path: '/refundView',
            component: () => import('../views/stock/refundView.vue'),
            meta: {title: '退貨'},
          },
          { // 遺失
            name: 'lostView',
            path: '/lostView',
            component: () => import('../views/stock/lostView.vue'),
            meta: {title: '遺失'},
          },
          { // 報廢
            name: 'scrapVirw',
            path: '/scrapVirw',
            component: () => import('../views/stock/scrapVirw.vue'),
            meta: {title: '報廢'},
          },
          { // 一般耗材
            name: 'generalConsumables',
            path: '/generalConsumables',
            component: () => import('../views/stock/generalConsumables.vue'),
            meta: {title: '一般耗材'},
          },
          { // 約診Call客
            name: 'appoinmentCallCustomer',
            path: '/appoinmentCallCustomer',
            component: () => import('../views/appointmentCallCustomer/AppointmentCallCustomer.vue'),
            meta: {title: '約診Call客'}
          },
          { // 療程專用卷查詢
            name: 'voucherRecord',
            path: '/voucherRecord',
            component: () => import('../views/voucherRecord/VoucherRecord.vue'),
            meta: {title: '療程專用卷查詢'}
          },
          { // 抽獎活動
            name: 'sweepstakesView',
            path: '/sweepstakesView/:redirect?',
            component: () => import('../views/stock/sweepstakesView.vue'),
            meta: {title: '抽獎活動'}
          },
          { // 抽獎
            path: '/lottery',
            children: [
              {
                name: 'lotteryIndex',
                path: '',
                component: () => import('../views/lottery/LotteryPageStandby.vue'),
                meta: {title: '抽獎待機'}
              },
              {
                name: 'lottery',
                path: ':activityId/:customerId',
                component: () => import('../views/lottery/LotteryPage.vue'),
                meta: {title: '抽獎'}
              }
            ]
          },
          { // 日報表
            name: 'dailyReport',
            path: '/dailyReport',
            component: () => import('../views/counter/dailyReport/layout/dailyReport.vue'),
            meta: {title: '日報表'},
            children: [
              { 
                path: 'salesInvoice',
                name:'salesInvoice',
                component: () => import('../views/counter/dailyReport/detail/SalesInvoice.vue')
              },
              { 
                path: 'refundsDetail',
                name:'refundsDetail',
                component: () => import('../views/counter/dailyReport/detail/RefundsDetail.vue')
              },
              { 
                path: 'unPaid',
                name:'unPaid',
                component: () => import('../views/counter/dailyReport/detail/UnPaid.vue')
              },
              { 
                path: 'anestheticCharges',
                name:'anestheticCharges',
                component: () => import('../views/counter/dailyReport/detail/AnestheticCharges.vue')
              },
              { 
                path: 'otherCharges',
                name:'otherCharges',
                component: () => import('../views/counter/dailyReport/detail/OtherCharges.vue')
              },
              { 
                path: 'opdClinic',
                name:'opdClinic',
                component: () => import('../views/counter/dailyReport/detail/OpdClinic.vue')
              },
              { 
                path: 'productSales',
                name:'productSales',
                component: () => import('../views/counter/dailyReport/detail/productSales/ProductSales.vue')
              },
              { 
                path: 'laserCompare',
                name:'laserCompare',
                component: () => import('../views/counter/dailyReport/detail/laserCompare/LaserCompare.vue')
              },
              { 
                path: 'microdiagnosisCompare',
                name:'microdiagnosisCompare',
                component: () => import('../views/counter/dailyReport/detail/microdiagnosisCompare/MicrodiagnosisCompare.vue')
              },
              { 
                path: 'storeValueSignature',
                name:'storeValueSignature',
                component: () => import('../views/counter/dailyReport/detail/StoreValueSignature.vue')
              },
              { 
                path: 'doctorFreeRecord',
                name:'doctorFreeRecord',
                component: () => import('../views/counter/dailyReport/detail/doctorFree/DoctorFreeRecord.vue')
              },  
              { 
                path: 'activityList',
                name:'activityList',
                component: () => import('../views/counter/dailyReport/detail/activityList/ActivityList.vue')
              },
              { 
                path: 'depositList',
                name:'depositList',
                component: () => import('../views/counter/dailyReport/detail/depositList/DepositList.vue'),
              },
              {
                path: 'voucherUse',
                name:'voucherUse',
                component: () => import('../views/counter/dailyReport/detail/voucherUse/VoucherUse.vue'),
              },
              {
                path: 'beautyConsumables',
                name:'beautyConsumables',
                component: () => import('../views/counter/dailyReport/detail/BeautyConsumables.vue'),
              },
              {
                path: 'reverseEntry',
                name:'reverseEntry',
                component: () => import('../views/counter/dailyReport/detail/ReverseEntry.vue'),
              },
              {
                path: 'lotteryWinnerList',
                name:'lotteryWinnerList',
                component: () => import('../views/counter/dailyReport/detail/lotteryWinnerList/LotteryWinnerList.vue'),
              },
            ]
          },
        ]
      },
      {
        path: '/',
        beforeEnter: () => roleControlMiddleware(['Administrator','HeadNurse','HeadReceptionis']),
        children: [
          { // 促銷活動管理
            name: 'eventManagement',
            path: '/eventManagement',
            component: () => import('../views/stock/eventManagement.vue'),
            meta: {title: '促銷活動管理'}
          },
          { // 產品進貨管理
            name: 'materialPurchaseManagement',
            path: '/materialPurchaseManagement',
            component: () => import('../views/stock/materialPurchaseManagement.vue'),
            meta: {title: '產品進貨管理'}
          },
          { // 設定組合產品
            name: 'setProductBundles',
            path: '/setProductBundles',
            component: () => import('../views/stock/setProductBundles.vue'),
            meta: {title: '設定組合產品'}
          },
          { // 盤點系統
            name: 'inventorySystem',
            path: '/inventorySystem',
            component: () => import('../views/stock/inventorySystem.vue'),
            meta: {title: '盤點系統'}
          },
        ]
      },
      {
        path: '/',
        beforeEnter: () => roleControlMiddleware(['Administrator','HeadNurse']),
        children: [
          { // 設定門診時間
            name: 'setOutpatientTime',
            path: '/setOutpatientTime',
            component: () => import('../views/setOutpatientTime.vue'),
            meta: {title: '設定門診時間'}
          },
          { // 門診預約時段條件
            name: 'outpatientTimeCondition',
            path: '/outpatientTimeCondition',
            component: () => import('../views/outpatientTimeCondition/OutpatientTimeCondition.vue'),
            meta: {title: '門診預約時段條件'}
          },
          { // 門診醫師時間
            name: 'outpatientDoctorTime',
            path: '/outpatientDoctorTime',
            component: () => import('../views/outpatientDoctorTime.vue'),
            meta: {title: '門診醫師時間'
            }
          },
          { // 員工管理
            name: 'employeeManagement',
            path: '/employeeManagement',
            component: () => import('../views/employeeManagement.vue'),
            meta: {title: '員工管理'}
          },
          { // 療程管理
            name: 'courseSetting',
            path: '/courseSetting',
            component: () => import('../views/courseSetting.vue'),
            meta: {title: '療程管理'}
          },
          { // 子療程管理
            name: 'subCourseManagement',
            path: '/subCourseManagement',
            component: () => import('../views/subCourseManagement.vue'),
            meta: {title: '子療程管理'}
          },
          { // 療程部位管理
            name: 'bodyPartSetting',
            path: '/bodyPartSetting',
            component: () => import('../views/bodyPartSetting.vue'),
            meta: {title: '療程部位管理'}
          },
          { // 設定美容師助理
            name: 'beauticianRelation',
            path: '/beauticianRelation',
            component: () => import('../views/beauticianRelation.vue'),
            meta: {title: '設定美容師助理'}
          },
          { // 達成率設定
            name: 'achievementSetting',
            path: '/achievementSetting',
            component: () => import('../views/stock/achievementSetting/AchievementSetting.vue'),
            meta: {title: '達成率設定'}
          },
          { // 每月達成率公式設定
            name: 'achievementFormulaSetting',
            path: '/achievementFormulaSetting',
            component: () => import('../views/achievementFormulaSetting/AchievementFormulaSetting.vue'),
            meta: {title: '每月達成率公式設定'}
          },
          { // 問卷管理
            name: 'formView',
            path: '/formView',
            component: () => import('../views/stock/formView/FormView.vue'),
            meta: {title: '問卷管理'}
          },
          { // 編輯日報表
            name: 'dailyReportEdit',
            path: '/dailyReportEdit',
            component: () => import('../views/counter/dailyReportEdit/dailyReportEdit.vue'),
            meta: {title: '編輯日報表'}
          },
          { // 折扣管理
            name: 'discountView',
            path: '/discountView',
            component: () => import('../views/stock/discountView.vue'),
            meta: {title: '折扣管理'}
          },
          { // 折扣管理
            name: 'courseConsumableCombineSetting',
            path: '/courseConsumableCombineSetting',
            component: () => import('../views/courseConsumableCombineSetting/CourseConsumableCombineSetting.vue'),
            meta: {title: '混和耗材設定'}
          },
        ]
      },
      {
        path: '/',
        beforeEnter: () => roleControlMiddleware(['Administrator','HeadNurse']),
        children: [
          { // 產品建檔
            name: 'materialArchiving',
            path: '/materialArchiving',
            component: () => import('../views/stock/materialArchiving.vue'),
            meta: {title: '產品建檔'}
          },
          { // 療程耗材進貨管理
            name: 'courseConsumableManagement',
            path: '/courseConsumableManagement',
            component: () => import('../views/stock/courseConsumableManagement/CourseConsumableManagement.vue'),
            meta: {title: '療程耗材進貨管理'}
          },
          
          { // 銷售產品類別管理
            name: 'salesProductCategoryManagement',
            path: '/salesProductCategoryManagement',
            component: () => import('../views/stock/salesProductCategoryManagement.vue'),
            meta: {title: '銷售產品類別管理'}
          },
          { // 銷售產品管理
            name: 'salesProductManagement',
            path: '/salesProductManagement',
            component: () => import('../views/stock/salesProductManagement.vue'),
            meta: {title: '銷售產品管理'}
          },
          { // 倉位管理
            name: 'setUpWarehouse',
            path: '/setUpWarehouse',
            component: () => import('../views/stock/setUpWarehouse.vue'),
            meta: {title: '倉位管理'}
          },
          { // 廠商管理
            name: 'vendorManagement',
            path: '/vendorManagement',
            component: () => import('../views/stock/vendorManagement.vue'),
            meta: {title: '廠商管理'}
          },
          { // 微整統計
            name: 'regularInventoryRecordAndSummary',
            path: '/regularInventoryRecordAndSummary',
            component: () => import('../views/regularInventoryRecordAndSummary/RegularInventoryRecordAndSummary.vue'),
            meta: {title: '微整統計'}
          }
        ]
      },
      {
        path: '/',
        beforeEnter: () => roleControlMiddleware(['Administrator', 'Doctor']),
        children: [
          { // 醫師業績
            path: '/doctorPerformance',
            component: () => import('../views/stock/doctorPerformance/DoctorPerformance.vue'),
            meta: {title: '醫師業績'},
            children: [
              {
                name: 'doctorPerformanceIndex',
                path: '',
                component: () => import('../views/stock/doctorPerformance/DoctorPerformanceDetail.vue'),
              },
              {
                name: 'doctorPerformancePersonalDetail',
                path: ':doctorId',
                component: () => import('../views/stock/doctorPerformance/DoctorPerformancePersonalDetail.vue'),
              }
            ]
          }
        ]
      },
      
      { // 微整治療臉圖
        name: 'courseOfTreatment',
        path: '/courseOfTreatment/:operator?/:customerId?/:arrivedTime?',
        component: () => import('../views/courseOfTreatment/courseOfTreatment.vue'),
        meta: {title: '微整治療臉圖'}
      },
      { // 病歷
        name: 'medicalRecordIndex',
        path: '/medicalRecord/:date/:customerId',
        component: () => import('../views/medicalRecord/medicalRecordIndex.vue'),
        meta: {title: '病歷'}
      },
      
    ],
  },
  { // 登入頁
    name: 'loginIndex',
    path: '/loginIndex',
    component: () => import('../views/loginIndex.vue'),
    meta: {title: '登入'}
  },
  { // 沒有權限
    name: 'no-permission',
    path: '/no-permission',
    component: () => import('../views/noPermission.vue'),
    meta: {title: '沒有權限'}
  },
  { // 每月醫師約診表
    name: 'doctorShift',
    path: '/doctorShift',
    component: () => import('../views/doctorShift/DoctorShift.vue'),
    meta: {title: '每月醫師約診表'}
  },
  { // 每日醫師約診表
    name: 'doctorShiftDaily',
    path: '/doctorShift/doctorShiftDaily',
    component: () => import('../views/doctorShift/DoctorShiftDaily.vue'),
    meta: {title: '每日醫師約診表'}
  },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(middleware)

export default router
